import { useState } from "react";
// import "./faq.css";
// import '../../src/components/balifaq.css';
import "./faq.css";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

import faqImage from "../../assets/images/banners/main-banner2.png";


const faqs = [
  {
    title: "What is included in the Sri Lanka tour packages?",
    text: "Our Sri Lanka tour packages include a comprehensive itinerary with accommodation in top-rated hotels, free meals, guided tours to popular attractions, and lots of fun. Depending on the Sri Lanka package, extras like entrance fees, special dinners, and local experiences might also be included. Check each  Sri Lanka package description for detailed inclusions.",
  },
  {
    title: "Do you offer Sri Lanka packages from Bangalore?",
    text: "Yes, we offer convenient Sri Lanka packages from Bangalore, which include a carefully planned itinerary to ensure a smooth and enjoyable trip. We handle all the details so you can relax and enjoy your Sri Lanka vacation package.",
  },
  {
    title: "What are the best Sri Lanka holiday packages for families?",
    text: "Our Sri Lanka holiday packages for families include visits to family-friendly attractions like national parks, cultural sites, beaches, and more. Sri Lanka Packages often include activities such as beach games and visits to other important places. We also ensure accommodations are family-friendly considering the individual requirements in the Sri Lanka Package.",
  },
  {
    title: "Can we customize our Sri Lanka tour package?",
    text: "Absolutely! We understand that each traveler has unique preferences. You can customize your Sri Lanka tour package by selecting specific destinations, choosing the type of accommodation, adding activities such as snorkeling or hiking, and adjusting the trip duration. Our team will work with you to create the perfect Sri Lanka package itinerary.",
  },
  {
    title: "Are there Sri Lanka tour packages for couples?",
    text: "Yes, we offer specially designed Sri Lanka tour packages for couples that focus on romantic experiences. These Sri Lanka  packages often include stays at luxury resorts, private beach dinners, and secluded tours to scenic spots, ensuring a memorable romantic Sri Lanka getaway.",
  },
  {
    title: "How long are the typical Sri Lanka holiday packages?",
    text: "Our Sri Lanka holiday packages typically range from 4 to 10 days, giving you enough time to explore various regions of the island. We also offer longer Sri Lanka packages for those who wish to delve deeper into the country's culture, history, and natural beauty. However, we can customize the trip length to fit your schedule.",
  },
  {
    title: "What is the best time to book Sri Lanka packages?",
    text: "The best time to book Sri Lanka packages depends on your preferred activities and destinations. The dry season from December to March is ideal for visiting the west and south coasts, while the east coast is best visited from May to September. Booking Sri Lanka Packages in advance ensures better availability and prices.",
  },
  {
    title: "What activities are included in the Sri Lanka tour package?",
    text: "Our Sri Lanka tour packages include a variety of activities to suit different interests. You can enjoy city tours, cultural visits to ancient temples and forts, beach activities, and adventure sports. Each Sri Lanka package offers a unique combination of activities.",
  },
  {
    title: "How do I book a Sri Lanka tour package with GoL Travels?",
    text: "Booking your Sri Lanka package with GoL Travels is easy. You can visit our website and browse through our range of Sri Lanka packages. Once you find a Sri Lanka package you like, you can fill out a booking form, and our team will get in touch to finalize the details. Alternatively, you can call our customer service for personalized assistance. We'll guide you through the booking process and help you plan the perfect trip.",
  },
  {
    title: "Do Sri Lanka packages cover all major tourist spots?",
    text: "Yes, our Sri Lanka packages are designed to cover all major tourist spots. This includes iconic locations like Colombo, Kandy, Galle, Sigiriya, Nuwara Eliya, and the beautiful beaches of Bentota and Mirissa. We ensure you get a well-rounded experience of Sri Lanka's diverse attractions. TheSri Lanka  packages are subject to availability and other aspects.",
  },
];

export default function Faq() {
  return (
    <>
    <Navbar />
    <div className="faq-container">
      <img src={faqImage} alt="terms-conditions" />
      <Accordion data={faqs} />
    </div>
    <Footer />
    </>
  );
}

function Accordion({ data }) {
  return (
    <>
      <h1 className="head-line">Frequently Asked Question's</h1>
      <div className="accordion123">
        {data.map((el, i) => (
          <AccordionItem
            title={el.title}
            text={el.text}
            num={i}
            key={el.title}
          />
        ))}
      </div>
    </>
  );
}

function AccordionItem({ num, title, text }) {
  const [isOpen, setIsOpen] = useState(false);

  function handleToggle() {
    setIsOpen((isOpen) => !isOpen);
  }

  return (
    <div className={`item ${isOpen ? "open" : ""}`} onClick={handleToggle}>
      <p className="number">{num < 9 ? `0${num + 1}` : num + 1}</p>
      <p className="title">{title}</p>
      <p className="icon">{isOpen ? "-" : "+"}</p>
      {isOpen && <div className="content-box">{text}</div>}
    </div>
  );
}
