import Sigiriya1 from "../../assets/images/top-destinations/Sigiriya1.png";
import Sigiriya2 from "../../assets/images/top-destinations/Sigiriya2.png";
// import Temple1 from "../../assets/images/top-destinations/Temple1.png";
// import Temple2 from "../../assets/images/top-destinations/Temple2.png";
// import Galle1 from "../../assets/images/top-destinations/Galle1.png";
// import Galle2 from "../../assets/images/top-destinations/Galle2.png";
import LotusTower1 from "../../assets/images/top-destinations/LotusTower3.png";
import LotusTower2 from "../../assets/images/top-destinations/LotusTower4.png";

import DestinationData from "./DestinationData"
import "./DestinationStyles.css"
import { useNavigate } from "react-router-dom";

const Destination = () => {
    const navigate = useNavigate();

    return (
        <>
        <div className="destination">
            <h1>Explore Our Top Destinations</h1>
            {/* <div className="destination-content">
               <p>Welcome to GoL Travels, your trusted Destination Management Company for Sri Lanka, a land that holds unparalleled beauty and rich culture. Often referred to as the "Pearl of the Indian Ocean," Sri Lanka is a treasure house of experiences, from peaceful beaches and misty hill stations to vibrant wildlife and UNESCO World Heritage Sites. At GoL Travels, we do more than simply move logistics to create meaningful journeys for your clients.
                <br/><br/>
                Whether it’s a beach holiday, immersive cultural tours, or thrilling wildlife safaris, we ensure every itinerary is detailed and seamless. Our local expertise allows us to provide exclusive access to unique destinations, sustainable travel solutions, and experiences that showcase Sri Lanka’s heart. Partner with us to offer your clients an exceptional Sri Lankan journey, complete with personalised service, reliable support, and memorable moments at every step.
                <br/><br/>
                Together, we create travel experiences that inspire and leave lasting impressions.
               </p>
            </div> */}
            

            <DestinationData
            className="first-des-reverse"
            heading = "Sigiriya Rock"
            text = "Sigiriya Rock Fortress, also known as the Lion Rock is a UNESCO World Heritage Site and one of Sri Lankas most iconic landmarks. Rising 200 meters above the surrounding plains, this ancient fortress is both an archaeological and architectural marvel. Built in the 5th century by King Kashyapa, it features remnants of a royal palace, vibrant frescoes, and the famous Mirror Wall. Visitors can climb the rock via a series of staircases that pass through the monumental Lions Gate, offering breathtaking views of the surrounding landscapes. Sigiriya is a testament to Sri Lankas rich history and engineering brilliance."
            img1 = {Sigiriya2}
            img2 = {Sigiriya1}
            />
           

            <DestinationData
            className="first-des"
            heading = "Iconic Lotus Tower"
            text = "The Lotus Tower (Nelum Kuluna) is a striking architectural marvel and the tallest structure in South Asia, located in Colombo, Sri Lanka. Standing at 356 meters, it is designed to resemble a blooming lotus, symbolizing the country’s cultural heritage and prosperity. The tower serves as a multi-functional space, featuring a telecommunications hub, observation decks, banquet halls, and entertainment areas. Visitors can enjoy panoramic views of Colombo and the surrounding coastline from its observation deck. At night, the tower comes alive with vibrant LED lights, making it a dazzling icon of Sri Lanka’s modernization and innovation."
            img1 = {LotusTower1}
            img2 = {LotusTower2}
            />    
        </div>
           <button className="moreDestinations" onClick={() => navigate("/more-destinations")}>More Destinations</button>
        </>
    )
}

export default Destination;