

import React from 'react';
import './Footer.css';
// import logo from '../assets/logo.png'; 
// import logo from '../../assets/images/logo1.png';
import logo from "../../assets/images/logo/Lanka-logo1.png";

import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';


function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section about">
          <img src={logo} alt="GOL Logo" className="footer-logo" />
          <p>
            GOL is entirely owned and managed by Gol Travels Lanka Pvt Ltd. GOL is striving hard for
            ultimate success and innovation. We are a registered company as a tour & travel company
            that aims to set clear goals, fix the priorities and organize the resources effectively.
          </p>
        </div>

        {/* Contact Information Section */}
        <div className="footer-section contact-info">
          <h3>Contact Us</h3>
          <p>Feel free to contact and reach us !!</p>
          {/* <p>📞 +94 0777904844</p> */}

          {/* Mobile redirect */}
            <p>
            <a
              href="tel:+94777904844"
              style={{
                margin: '0 0px',
                textDecoration: 'none',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <PhoneIcon style={{ marginRight: '10px' }} /> +94 0777904844
            </a>
            </p>
          
          {/* Mail redirect for customers */}
            <p>
              <a
                href="mailto:info@goltravelsrilanka.com"
                style={{
                  margin: '0 0px',
                  textDecoration: 'none',
                  color: 'white',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <MailIcon style={{ marginRight: '10px', color: 'white' }} /> info@goltravelsrilanka.com
              </a>
            </p>

          {/* Location redirect */}
            <p>
            <a
                 href="https://maps.app.goo.gl/6Sih2swamKHhQE1p7"
                 target="_blank"
                 rel="noopener noreferrer"
                 style={{ 
                   margin: '0 0px', 
                   textDecoration: 'none', 
                   color: 'white' 
                  }}
                 >
                  <LocationOnIcon style={{ marginRight: '10px', color: 'white' }}/> 
            </a>
                242/2 Nawala Road Nawala, Colombo, SriLanka
            </p>

          
          <h3>Branches</h3>
          <p className='branches' style={{color: "white"}}>India, Maldives, Thailand</p>

          {/* Social Media Links - FB, Insta, X */}
          <h3>Follow us on</h3>
          <p> 
           {/* Instagram redirect */}
              <a
                href="https://www.instagram.com/gol_srilanka/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ margin: '0 0px', textDecoration: 'none', color: 'white' }}
              >
                <InstagramIcon />
              </a>
              
              {/* FB redirect */}
              <a
                href="https://www.facebook.com/profile.php?id=61554362077297"
                target="_blank"
                rel="noopener noreferrer"
                style={{ margin: '0 15px', textDecoration: 'none', color: 'white' }}
              >
                <FacebookIcon />
              </a>
            
            {/* Twitter redirect */}
              <a
                href="https://www.x.com/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ margin: '0 0px', textDecoration: 'none', color: 'white' }}
              >
                <XIcon />
              </a>
          
          </p>
        </div>

        {/* Newsletter Subscription Section */}
        <div className="footer-section newsletter">
          <h3>Subscribe our newsletter for more update & news !!</h3>
          <form className="newsletter-form">
            <input type="email" placeholder="Enter Your Email" className="newsletter-input" />
            <button type="submit" className="newsletter-button">SUBSCRIBE</button>
          </form>
        </div>
      </div>

      {/* Bottom Copyright Section */}
      <div  className="footer-bottom">
        <p>Copyright, Gol Travels Lanka Pvt Ltd. All rights reserved.</p>
        <div className="footer-links">
          <a href="/privacy-policy">Privacy Policy</a> | <a href="/terms-and-conditions">Term & Condition</a> | <a href="/faq">FAQ</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

// ✉️