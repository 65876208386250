import { Col, Container, Row } from "react-bootstrap";
import Destination from "../components/Destinations/Destination";
import Footer from "../components/Footer/Footer";
import MasonryImagesGallery from "../components/Image-gallery/MasonryImagesGallery";
import Navbar from "../components/Navbar/Navbar"
import Hero from "../components/Hero/hero";
import Trip from "../components/BestPlaces/trip";
import Packages from "../components/Packages/Packages";
import mainBanner from "../assets/images/banners/main-banner12.png";
import Carousel from "../components/Testimonial/Testimonials";
import GallerySection from "../components/Gallery/GallerySection";
// import AutoLaunchModal from "../components/EnquiryForm/PopupModel";
// import HomePage from "../components/SamplePackages/homePage";
import GetCallBack from "../components/GetCallBack/GetCallBack";
import Contact from "./Contact";
// import RequestCallback from "../pages/RequestCallback";
// import { useRouteError } from "react-router-dom";

function Home () {
  // const router = useRouteError();

  // const handleRequestCallback = () => {
  //   router.push("/RequestCallback"); // Redirects to the RequestCallback component
  // };
    return (
        <>
          <Navbar/>
            <Hero
            cName="hero"
            heroImg={mainBanner}
            // title="Experience Paradise - explore Sri Lanka's rich heritage"
            text="Sri Lanka : Where every moment is a journey of discovery"
            buttonText="Get Agent help"
            //  url="/GetCallBack"
            btnClass="show"
            //  onClick={Contact}
            />
          <Packages />
          <Destination />
          <Trip/>
          <Carousel  />
          {/* <MasonryImagesGallery /> */}
          <GallerySection />
          <Footer/>
        </>
    )
}

export default Home;