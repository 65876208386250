import React from "react";
import "./GallerySection.css";

// import galleryImage1 from "../../assets/images/gallery-section/gallery-img01.jpg";
// import galleryImage2 from "../../assets/images/gallery-section/gallery-img02.jpg";
// import galleryImage3 from "../../assets/images/gallery-section/gallery-img03.jpg";
// import galleryImage4 from "../../assets/images/gallery-section/gallery-img04.jpg";
// import galleryImage5 from "../../assets/images/gallery-section/gallery-img05.jpg";
// import galleryImage6 from "../../assets/images/gallery-section/gallery-img06.jpg";
// import galleryImage7 from "../../assets/images/gallery-section/gallery-img07.jpg";
// import galleryImage8 from "../../assets/images/gallery-section/gallery-img08.jpg";
// import galleryImage9 from "../../assets/images/gallery-section/gallery-img08.jpg";
// import galleryImage10 from "../../assets/images/gallery-section/gallery-img08.jpg";
// import galleryImage11 from "../../assets/images/gallery-section/gallery-img08.jpg";

import galleryImage12 from "../../assets/galleryImage/Galle1.png";
import galleryImage13 from "../../assets/galleryImage/LotusTower2.png";
import galleryImage14 from "../../assets/galleryImage/gallery-img03.jpg";
import galleryImage15 from "../../assets/galleryImage/gallery-img04.jpg";
import galleryImage16 from "../../assets/galleryImage/gallery-img05.jpg";
import galleryImage18 from "../../assets/galleryImage/Rawana-Falls.png";
import galleryImage17 from "../../assets/galleryImage/gallery-img06.jpg";
import galleryImage19 from "../../assets/galleryImage/Udawalawe-Park.png";
import galleryImage20 from "../../assets/galleryImage/Traditional.png";
import galleryImage21 from "../../assets/galleryImage/Traditional2.png";
import galleryImage22 from "../../assets/galleryImage/NineArchBridge.png";



// import galleryImage12 from "../../assets/images/gallery-section/gallery-img08.jpg";
// import galleryImage13 from "../../assets/images/gallery-section/gallery-img03.jpg";
// import galleryImage14 from "../../assets/images/gallery-section/gallery-img08.jpg";
// import galleryImage15 from "../../assets/images/gallery-section/gallery-img01.jpg";
// import galleryImage16 from "../../assets/images/gallery-section/gallery-img08.jpg";
// import galleryImage17 from "../../assets/images/gallery-section/gallery-img04.jpg";
// import galleryImage18 from "../../assets/images/gallery-section/gallery-img08.jpg";

const images = [
  // galleryImage1, // Replace with your actual image URLs
  // galleryImage2,
  // galleryImage3,
  // galleryImage4,
  // galleryImage5,
  // galleryImage6,
  // galleryImage7,
  // galleryImage8,
  // galleryImage9,
  // galleryImage10,
  // galleryImage11,

  galleryImage12,
  galleryImage13,
  galleryImage14,
  galleryImage15,
  galleryImage16,
  galleryImage17,
  galleryImage18,
  galleryImage19,
  galleryImage20,
  galleryImage21,
  galleryImage22,
];

const GallerySection = () => {
  return (
    <div className="gallery-container">
      <h1>Gallery </h1>
      <div className="gallery-grid">
        {images.map((image, index) => (
          <div key={index} className={`gallery-item item-${index + 1}`}>
            <img src={image} alt={`Gallery item ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default GallerySection;
