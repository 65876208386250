import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";

import termsCondition from "../../assets/images/banners/main-banner2.png";
<img src={termsCondition} alt="terms-conditions" />

const TermsConditions = () => {
    return (
        <>
        <Navbar />
        <div>
          <img src={termsCondition} alt="terms-conditions" />
          <h1>Terms conditions page</h1>
        </div>
        <Footer />
        </>
    )
}

export default TermsConditions;