import "./TripStyles.css"
import TripData from "./TripData";
// import Trip1 from "../assets/germany.jpg";
import Trip2 from "../../assets/switzerland.jpg";
import Trip3 from "../../assets/norway.jpg";
import ArchBridge from "../../assets/images/top-destinations/9Arch-bridge.png";
import LotusTower from "../../assets/images/top-destinations/LotusTower2.png";
import SigariyaRock from "../../assets/images/top-destinations/Sigiriya-Rock-1.png";

function Trip() {
    return (
        <div className="trip">
            {/* <h1>Recent Trips</h1> */}
            <h1>Best Places's to Visit</h1>
            {/* <p>You can discover unique destinations using google Maps.</p> */}
            <div className="tripcard">
                <TripData
                imgs = {SigariyaRock}
                heading = "Trip in Sigiriya Rock"
                text = "Sigiriya Rock, or Lion Rock, is a UNESCO World Heritage Site in Sri Lanka. This iconic 5th-century fortress showcases ancient frescoes, a mirror wall, and landscaped gardens. Rising 200 meters, it offers breathtaking views and a glimpse into Sri Lanka's rich history and architectural brilliance—a must-visit destination for all travelers."
                />

                <TripData
                imgs = {LotusTower}
                heading = "Trip in Lotus Tower"
                text = "The Lotus Tower, or Nelum Kuluna, is a striking landmark in Colombo, Sri Lanka, and the tallest structure in South Asia. Standing at 350 meters, it features a lotus-inspired design symbolizing purity. The tower houses observation decks, a revolving restaurant, and entertainment spaces, offering breathtaking city and ocean views."
                />

                <TripData
                imgs = {ArchBridge}
                heading = "Trip in Nine Arch Bridge"
                text = "The Nine Arch Bridge, located in Ella, Sri Lanka, is a marvel of colonial-era engineering. Built entirely of stone and brick without steel, this iconic viaduct features nine stunning arches set amidst lush greenery and tea plantations. A popular tourist attraction, it’s perfect for photography and train-watching in a scenic setting."
                />
            </div>
        </div>
        
    )
}

export default Trip;