// import "./ContactFormStyles.css"
import GetCallBack from "../GetCallBack/GetCallBack";

function ContactForm () {
    return (
        <div className="form-container">
            <GetCallBack />
        </div>
    ) 
}

export default ContactForm;