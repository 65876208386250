// import { Route, Routes } from "react-router-dom";
// import './App.css';
// import Home from './routes/Home';
// import About from './routes/About';
// import Service from './routes/Service';
// import Contact from './routes/Contact';
// import ScrollToTop from "react-scroll-up";
// // import Packages from "./components/Packages";
// import PackagesPage from "./routes/Packages";
// import WhatWeDos from "./routes/WhatWeDo";
// import upArrow from './assets/images/up-arrow-1.png'
// import ViewDetailsPage from "./routes/ViewDetails";
// import { EnquiryEmail } from "./components/EnquiryForm/EnquireEmail";
// import GolBlogList from "./pages/GolBlogs/BlogList";
// import FullBlogPost from "./pages/GolBlogs/FullBlogPost";
// import samplePosts from "./assets/data/BlogsData/samplePosts";
// import { useState } from "react";
// // import BlogsPage from "./routes/Blogs";
// // import RequestCallback from "./pages/RequestCallback";

// function App() {
//   const [posts, setPosts] = useState(samplePosts);
//   return (
//     <div className="App">
//       <Routes>
//         <Route path="/" element={<Home/>}/>
//         <Route path="/about" element={<About/>}/>
//         <Route path="/service" element={<Service/>}/>
//         <Route path="/contact" element={<Contact/>}/>
//         <Route path="/packages" element={<PackagesPage/>}/>
//         <Route path="/what-we-do" element={<WhatWeDos />}/>
//         <Route path="/view-details/:title" element={<ViewDetailsPage />}/>
//         <Route path="/enquiry" element={<EnquiryEmail />} />

//         <Route path="/blogs/" element={<GolBlogList posts={posts} />} />
//         <Route path="/blogs/:id" element={<FullBlogPost posts={posts} />} />
//         {/* <Route path="/blogs/:id" element={<BlogsPage />} /> */}
//         {/* <Route path="/enquiry" element={<EnquiryEmail />} /> */}
//         {/* <Route path="/request-callback" element={<RequestCallback />} /> */}
//       </Routes>
//       <ScrollToTop showUnder={160} style={{ zIndex: 1000}} >
//         <img src={upArrow} alt='Scroll to top button' style={{width: '50px'}} />
//       </ScrollToTop>
      
      
//     </div>
//   );
// }

// export default App;


// // https://milosjanda.github.io/react-scroll-up/img/up_arrow_round.png

import { Route, Routes } from "react-router-dom";
import './App.css';
import Home from './routes/Home';
import About from './routes/About';
import Service from './routes/Service';
import Contact from './routes/Contact';
import ScrollToTop from "react-scroll-up";
import PackagesPage from "./routes/Packages";
import WhatWeDos from "./routes/WhatWeDo";
import upArrow from './assets/images/up-arrow-1.png';
import ViewDetailsPage from "./routes/ViewDetails";
import { useState } from "react";
import { EnquiryEmail } from "./components/EnquiryForm/EnquireEmail";
import GolBlogList from "./pages/GolBlogs/BlogList";
import FullBlogPost from "./pages/GolBlogs/FullBlogPost";
import samplePosts from "./assets/data/BlogsData/samplePosts";
import PackageDetails from "./components/SamplePackages/PackageDetails";
import HomePage from "./components/SamplePackages/homePage";
import GetCallBack from "./components/GetCallBack/GetCallBack";
import ContactForm from "./components/ContactUs/ContactForm";
import MoreDestination from "./components/Destinations/MoreDestinations";
import ScrollToTopLine from "./components/ScrollToTop";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import TermsConditions from "./pages/Terms-Conditions/TermsConditions";
import Faq from "./pages/Faq/Faq";

function App() {
  const [posts, setPosts] = useState(samplePosts);

  return (
    <div className="App">
      <ScrollToTopLine />  {/* when opens next page it moves to the top of the page */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/" element={<HomePage />} />    {/* Sample Package code */}
        <Route path="/about" element={<About />} />
        <Route path="/service" element={<Service />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/packages" element={<PackagesPage />} />
        <Route path="/what-we-do" element={<WhatWeDos />} />
        <Route path="/view-details/:title" element={<ViewDetailsPage />} />
        <Route path="/enquiry" element={<EnquiryEmail />} />
        <Route path="/blogs" element={<GolBlogList posts={posts} />} />      {/* Blog Routes */}
        <Route path="/blogs/:id" element={<FullBlogPost posts={posts} />} />
        <Route path="/package/:id" element={<PackageDetails />} /> 
        <Route path="/GetCallBack" element={<GetCallBack />} />  {/* Get Call Back */}
        <Route path="/ContactForm" element={<ContactForm />} />
        <Route path="/more-destinations" element={<MoreDestination />} />   {/* More destinations */}

        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsConditions />} />
        <Route path="/faq" element={<Faq />} />
      </Routes>

      <ScrollToTop showUnder={160} style={{ zIndex: 1000 }}>
        <img src={upArrow} alt="Scroll to top button" style={{ width: '50px' }} /> {/* Scroll Up button */}
      </ScrollToTop>
    </div>
  );
}

export default App;
