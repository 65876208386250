import Navbar from "../components/Navbar/Navbar";
import Hero from "../components/Hero/hero";
// import travelImg from "../assets/contact.jpg";
import travelImg from "../assets/images/banners/2.png";
import Footer from "../components/Footer/Footer";
// import ContactForm from "../components/ContactUs/ContactForm";
import ContactForm from "../components/ContactUs/ContactForm";
import "./routes.css"

function Contact () {
    return (
        <>
        <Navbar/>
         <Hero
         cName="hero-mid"
         heroImg={travelImg}
         title=""
         btnClass="hide"
         />
         <ContactForm />
         <Footer/>
        </>
    )


}

export default Contact;