import banner from "../../assets/images/banners/4.png";
import Sigiriya1 from "../../assets/images/top-destinations/Sigiriya1.png";
import Sigiriya2 from "../../assets/images/top-destinations/Sigiriya2.png";
import Temple1 from "../../assets/images/top-destinations/Temple1.png";
import Temple2 from "../../assets/images/top-destinations/Temple2.png";
import Galle1 from "../../assets/images/top-destinations/Galle1.png";
import Galle2 from "../../assets/images/top-destinations/Galle2.png";
import LotusTower1 from "../../assets/images/top-destinations/LotusTower3.png";
import LotusTower2 from "../../assets/images/top-destinations/LotusTower4.png";

import DestinationData from "./DestinationData"
import "./DestinationStyles.css"
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

const MoreDestination = () => {
    return (
        <>
        <div className="destinations-navbar">
          <Navbar />
        </div>

        <img src={banner} alt="More-destinations-banner" /> 
          
        <div className="destination">
            <div className="destination-content">
            <h1>Explore Our Top Destinations</h1>
               <p>Welcome to GoL Travels, your trusted Destination Management Company for Sri Lanka, a land that holds unparalleled beauty and rich culture. Often referred to as the "Pearl of the Indian Ocean," Sri Lanka is a treasure house of experiences, from peaceful beaches and misty hill stations to vibrant wildlife and UNESCO World Heritage Sites. At GoL Travels, we do more than simply move logistics to create meaningful journeys for your clients.
                <br/><br/>
                Whether it’s a beach holiday, immersive cultural tours, or thrilling wildlife safaris, we ensure every itinerary is detailed and seamless. Our local expertise allows us to provide exclusive access to unique destinations, sustainable travel solutions, and experiences that showcase Sri Lanka’s heart. Partner with us to offer your clients an exceptional Sri Lankan journey, complete with personalised service, reliable support, and memorable moments at every step.
                <br/><br/>
                Together, we create travel experiences that inspire and leave lasting impressions.
               </p>
            </div>
            <DestinationData
            className="first-des"
            heading = "Temple of the Tooth"
            text = {<p> The Temple of the Tooth (Sri Dalada Maligawa) is a sacred Buddhist temple located in Kandy, Sri Lanka. It houses the revered relic of the tooth of Lord Buddha, making it a site of immense spiritual significance for Buddhists worldwide. Nestled within the historic royal palace complex, the temple features intricate architecture adorned with carvings, frescoes, and traditional Kandyan artistry. The annual Esala Perahera festival, a grand cultural procession, further enhances its prominence. Visitors are welcomed to witness serene rituals and immerse themselves in the tranquil ambiance, making the temple a must-visit destination for cultural and spiritual exploration.</p>}
            img1 = {Temple2}
            img2 = {Temple1}
            />

            <DestinationData
            className="first-des-reverse"
            heading = "Sigiriya Rock"
            text = {<p>Sigiriya Rock Fortress, also known as the Lion Rock is a UNESCO World Heritage Site and one of Sri Lankas most iconic landmarks. Rising 200 meters above the surrounding plains, this ancient fortress is both an archaeological and architectural marvel. Built in the 5th century by King Kashyapa, it features remnants of a royal palace, vibrant frescoes, and the famous Mirror Wall. Visitors can climb the rock via a series of staircases that pass through the monumental Lions Gate, offering breathtaking views of the surrounding landscapes. Sigiriya is a testament to Sri Lankas rich history and engineering brilliance.</p>}
            img1 = {Sigiriya2}
            img2 = {Sigiriya1}
            />
            <DestinationData
            className="first-des"
            heading = "Galle Fort"
            text = {<p>Galle Fort, a UNESCO World Heritage Site, is a historic gem located on the southern coast of Sri Lanka. Originally built by the Portuguese in the 16th century and later fortified by the Dutch, this iconic site showcases a blend of European and South Asian architectural styles. The fort’s cobblestone streets are lined with colonial-era buildings, boutique shops, cafes, and museums. Visitors can explore landmarks like the Galle Lighthouse, the Dutch Reformed Church, and the fort walls offering panoramic views of the ocean. Galle Fort is a living testament to Sri Lanka's rich colonial past and cultural heritage.</p>}
            img1 = {Galle1}
            img2 = {Galle2}
            />

            <DestinationData
            className="first-des-reverse"
            heading = "Iconic Lotus Tower"
            text = {<p>The Lotus Tower (Nelum Kuluna) is a striking architectural marvel and the tallest structure in South Asia, located in Colombo, Sri Lanka. Standing at 356 meters, it is designed to resemble a blooming lotus, symbolizing the country’s cultural heritage and prosperity. The tower serves as a multi-functional space, featuring a telecommunications hub, observation decks, banquet halls, and entertainment areas. Visitors can enjoy panoramic views of Colombo and the surrounding coastline from its observation deck. At night, the tower comes alive with vibrant LED lights, making it a dazzling icon of Sri Lanka’s modernization and innovation.</p>}
            img1 = {LotusTower1}
            img2 = {LotusTower2}
            />


            <DestinationData
            className="first-des"
            heading = "Nine Arch Bridge."
            text ={<p><a href="https://goltravelssrilanka.com/package/3">The Nine Arch Bridge,</a> an iconic colonial-era railway structure, is located in Ella, Sri Lanka. Surrounded by lush green tea plantations and dense jungle, it stands as a testament to Sri Lanka’s engineering and architectural heritage. Constructed during the British colonial period, the bridge is made entirely of stone and cement, without the use of steel. Spanning 91 meters and rising to a height of 24 meters, its nine elegant arches create a breathtaking sight, especially when framed by mist or as a train passes over it. This picturesque landmark is a popular destination for photography, hiking, and marveling at history amidst nature.</p>}
            img1 = {LotusTower1}
            img2 = {LotusTower1}
            />
            
            <DestinationData
            className="first-des-reverse"
            heading = "Ambuluwawa Tower."
            text = {<p><a href="https://goltravelssrilanka.com/package/1">Ambuluwawa Tower</a>, located in Gampola, Sri Lanka, is a unique multi-religious site symbolizing harmony and unity among different faiths. Perched atop Ambuluwawa mountain, the site features a spiraling tower offering panoramic views of the surrounding landscapes, including lush greenery, distant hills, and nearby towns. The complex includes places of worship for Buddhism, Hinduism, Islam, and Christianity, emphasizing interfaith coexistence. The tower’s striking design, with its winding staircase and adventurous climb, attracts visitors seeking both spiritual inspiration and thrilling experiences. A visit to Ambuluwawa provides a blend of serenity, breathtaking vistas, and cultural enrichment.</p>}
            img1 = {LotusTower1}
            img2 = {LotusTower1}
            />
            
            <DestinationData
            className="first-des"
            heading = "Pinnawala Elephant Orphanage."
            text = {<p>Pinnawala Elephant Orphanage, located in Sri Lanka, is a renowned sanctuary dedicated to the care and conservation of orphaned and injured elephants. Established in 1975, it provides a safe haven for these majestic creatures, allowing them to roam freely in a semi-natural habitat. Visitors can witness various activities, including bottle-feeding baby elephants, observing them bathe in the nearby river, and learning about their rehabilitation process. The orphanage plays a vital role in wildlife conservation and offers a unique opportunity to connect with elephants up close while promoting awareness of their protection. It’s a must-visit destination for nature lovers and wildlife enthusiasts.</p>}
            img1 = {LotusTower1}
            img2 = {LotusTower1}
            />

            <DestinationData
            className="first-des-reverse"
            heading = "Udawalawe National Park"
            text = {<p><a href="https://goltravelssrilanka.com/package/1">Udawalawe National Park</a>, located in southern Sri Lanka, is a haven for wildlife enthusiasts and nature lovers. Spanning over 30,000 hectares, the park is renowned for its large population of wild elephants, offering visitors an opportunity to observe these majestic creatures in their natural habitat. The park’s diverse ecosystems, including grasslands, marshes, and forests, are home to a variety of animals such as leopards, water buffalo, deer, and an array of bird species. Jeep safaris are a popular way to explore the park and capture unforgettable wildlife moments. Udawalawe is not only a wildlife hotspot but also a testament to Sri Lanka's commitment to conservation.</p>}
            img1 = {LotusTower1}
            img2 = {LotusTower1}
            />

            <DestinationData
            className="first-des"
            heading = "A Traditional Kandyan Dance Show"
            text = {<p> A <a href="https://goltravelssrilanka.com/package/2">Traditional Kandyan Dance</a>  Show offers a mesmerizing glimpse into Sri Lanka's rich cultural heritage. Originating in the hill country, Kandyan dance is characterized by its vibrant costumes, intricate movements, and rhythmic drumming. Performers, adorned in elaborate attire with ornate headdresses, enact stories rooted in mythology, spirituality, and folklore. The energetic choreography often includes acrobatic feats, such as fire dances and drum-spinning. Accompanied by traditional instruments like the geta bera drums, the performances create a captivating auditory and visual spectacle. A Kandyan dance show is a must-see cultural experience, reflecting the artistry and traditions of Sri Lanka's ancient kingdoms.</p>}
            img1 = {LotusTower1}
            img2 = {LotusTower1}
            />
            
            <DestinationData
            className="first-des-reverse"
            heading = "The Bhakta Hanuman Temple"
            text = {<p>
            The <span><a href="https://goltravelssrilanka.com/package/1">Bhakta Hanuman Temple</a></span>, located in the lush highlands of Ramboda, Sri Lanka, is a sacred site dedicated to Lord Hanuman, a revered figure in Hinduism. Perched amidst serene tea estates, the temple offers breathtaking views of the surrounding valleys and mountains. It is believed to mark a location linked to the Ramayana, where Hanuman rested during his quest to find Sita. The temple’s peaceful ambiance, coupled with its spiritual significance, attracts devotees and visitors alike. The striking statue of Lord Hanuman, the focal point of worship, inspires awe and reverence. A visit here blends spirituality with natural beauty.
             </p>}
            img1 = {LotusTower1}
            img2 = {LotusTower1}
            />
        </div>
        <Footer />
        </>
    )
}

export default MoreDestination;