import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Packages.css';
// import hero1 from '../../assets/images/packageImages/hero1.webp';

import hero1 from "../../assets/images/packages/1.png";
import hero2 from "../../assets/images/packages/2.png";
import hero3 from "../../assets/images/packages/3.png";
import hero4 from "../../assets/images/packages/4.png";
import hero5 from "../../assets/images/packages/5.png";

// import hero2 from '../../assets/images/packageImages/hero1.webp';
// import { srilanka } from '../../assets/data/srilanka';
// import srilanka from "../assets/data/srilanka";


const packagesData = [
  {
    id: 1,
    title: 'Kandy: A Jewel in the Heart of Sri Lank',
    description: "Kandy is a cultural and scenic city in central Sri Lanka, known for its beautiful lake, ancient temples, and the iconic Temple of the Tooth, a UNESCO World Heritage site.",
    duration: '4N/5D',
    pax: 2,
    location: 'Sri Lanka',
    price: 625,
    reviews: 29,
    rating: 4,
    image: hero1,
  },
  {
    id: 2,
    title: 'Unwind and Explore: The Allure of Bentota',
    description: 'Bentota is a coastal town in Sri Lanka, famous for its pristine beaches, water sports, and luxury resorts. Its also home to the Bentota River and beautiful botanical gardens.',
    duration: '4N/5D',
    pax: 2,
    location: 'Sri Lanka',
    price: 918,
    reviews: 59,
    rating: 4.5,
    image: hero2,
  },
  {
    id: 3,
    title: 'Discover the Charm of Nuwara Eliya',
    description: 'Nuwara Eliya, located in Sri Lankas central highlands, is known for its cool climate, lush tea plantations, and scenic landscapes. Often called "Little England," it offers colonial charm and natural beauty.',
    duration: '4N/5D',
    pax: 2,
    location: 'Sri Lanka',
    price: 636,
    reviews: 46,
    rating: 4.3,
    image: hero3,
  },
  {
    id: 4,
    title: 'The Jewel of Sri Lankas Cultural Heritage',
    description: 'Dambulla, a historic city in Sri Lanka, is renowned for the Dambulla Cave Temple, a UNESCO World Heritage site. It features stunning ancient frescoes and statues of Buddha in serene surroundings.',
    duration: '4N/5D',
    pax: 1,
    location: 'Sri Lanka',
    price: 665,
    reviews: 19,
    rating: 4.7,
    image: hero4,
  },
  {
    id: 5,
    title: 'Wild Encounters and Coastal Bliss',
    description: 'Udawalawe is a wildlife sanctuary in Sri Lanka, famous for its elephants. Mirissa, a coastal town, offers beautiful beaches, whale watching, and vibrant nightlife, making it a popular travel destination.',
    duration: '4N/5D',
    pax: 2,
    location: 'Sri Lanka',
    price: 643,
    reviews: 33,
    rating: 4.3,
    image: hero5,
  },
];

const Packages = () => {
  // const navigate = useNavigate();

  // const handleBookNow = (title) => {
  //   navigate(`/view-details/${title}`);
  // };

  return (
    <div className="package-cards-container">
      
      <h2>Checkout Our Packages</h2>
      {/* <p className='package-para'>Planning a Sri Lanka holiday package from Bangalore or anywhere else could be mindboggling. Look no further. Our Sri Lanka tour packages are designed to offer the best of this island paradise. We combine local expertise with a passion for travel to create unforgettable experiences. Our Sri Lanka tour package for couples promises romance and lots of fun, while our family packages ensure fun and adventure for all ages.</p> */}

      {packagesData.map((pkg) => (
        <div key={pkg.id} className="package-card">
          <Link to={`/package/${pkg.id}`}>
          </Link>
          <img
            src={pkg.image}
            alt={pkg.title}
            className="package-image"
          />
          <div className="package-details">
            <div className="text-section">
              <h3>{pkg.title}</h3>
              <p>{pkg.description}</p>
              <div className="package-info">
                <span>🕒 {pkg.duration}</span> | <span>👥 pax: {pkg.pax}</span> | <span>📍 {pkg.location}</span>
              </div>
            </div>

            <div className="pricing-section">
              
              <Link to={`/package/${pkg.id}`} style={{ textDecoration: "none", color: "white" }} >
                <p className="reviews">({pkg.reviews} reviews) {Array.from({ length: 5 }).map((_, index) => (
                 <span key={index} className="star">{index < Math.round(pkg.rating) ? '★' : '☆'}</span>
                 ))}
                </p>
                <h2>{pkg.price} USD</h2>
                <p>/ per couple</p>
              </Link>

              {/* <button className="enquire">Enquire Now</button> */}
              {/* <button className="book-now" onClick={() => handleBookNow(pkg.id)}>BOOK NOW</button> */}
              <Link to={`/package/${pkg.id}`} style={{ textDecoration: "none", color: "white" }}>
               <button style={{ background: "rgb(240, 240, 240)", color: "black", padding: "10px", border: "none", borderRadius: "5px" }}>
               Enquire Now
              </button>
            </Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Packages;