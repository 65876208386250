import Navbar from "../components/Navbar/Navbar";
import Hero from "../components/Hero/hero";
// import travelImg from "../assets/travel.jpg";
import travelImg from "../assets/images/banners/1.png";
import Footer from "../components/Footer/Footer";
import WhatWeDo from "../pages/WhatWeDo/WhatWeDo";


function WhatWeDos () {
    return (
        <>
        <Navbar/>
         <Hero
         cName="hero-about"
         heroImg={travelImg}
         title=""
         btnClass="hide"
         width="100%"
         height="600px"
         />
         <WhatWeDo />
         <Footer/>
        </>
        
    )


}

export default WhatWeDos;