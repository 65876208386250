// import Footer from "../../components/Footer/Footer";
// import Navbar from "../../components/Navbar/Navbar";

// import privacyPolicyImage from "../../assets/images/banners/main-banner2.png";

// const PrivacyPolicy = () => {
//     return (
//         <>
//         <Navbar />
//         <div>
//           <img src={privacyPolicyImage} alt="terms-conditions" />
//           <h1>Privacy Policy page</h1>
//         </div>
//         <Footer />
//         </>
//     )
// }

// export default PrivacyPolicy;

import { useState } from "react";
// import "./faq.css";
// import '../../src/components/balifaq.css';
// import "../Faq/faq.css";
import "./PrivacyPolicy.css"
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

import faqImage from "../../assets/images/banners/main-banner2.png";


const faqs = [
  {
    title: "What payment methods do you accept?",
    text: "We accept credit/debit cards, online banking, and cash payments at our branch.",
  },
  {
    title: "What is the cancellation policy?",
    text: "Cancellations made 7 days prior to travel may receive a full refund, while cancellations made within 7 days may incur a penalty.",
  },
  {
    title: "How long does it take for refunds to process?",
    text: "Refunds typically take 7-10 business days to process.",
  },
  {
    title: "Are there any hidden fees?",
    text: "No, all fees are clearly stated at the time of booking.",
  },
  {
    title: "Can I get a refund if I change my travel plans?",
    text: "Refund eligibility depends on the timing of the change. Contact customer service for assistance.",
  }
];

export default function PrivacyPolicy() {
  return (
    <>
    <Navbar />
    <div className="faq-container">
      <img src={faqImage} alt="terms-conditions" />
      <Accordion data={faqs} />
    </div>
    <Footer />
    </>
  );
}

function Accordion({ data }) {
  return (
    <>
      <h1 className="head-line">Privacy & Policy</h1>
      <div className="accordion123">
        {data.map((el, i) => (
          <AccordionItem
            title={el.title}
            text={el.text}
            num={i}
            key={el.title}
          />
        ))}
      </div>
    </>
  );
}

function AccordionItem({ num, title, text }) {
  const [isOpen, setIsOpen] = useState(false);

  function handleToggle() {
    setIsOpen((isOpen) => !isOpen);
  }

  return (
    <div className={`item ${isOpen ? "open" : ""}`} onClick={handleToggle}>
      <p className="number">{num < 9 ? `0${num + 1}` : num + 1}</p>
      <p className="title">{title}</p>
      <p className="icon">{isOpen ? "-" : "+"}</p>
      {isOpen && <div className="content-box">{text}</div>}
    </div>
  );
}
