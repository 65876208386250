import Navbar from "../components/Navbar/Navbar";
import Hero from "../components/Hero/hero";
import travelImg from "../assets/travel.jpg";
import Footer from "../components/Footer/Footer";
import ViewDetails from "../pages/ViewDetails";


function ViewDetailsPage () {
    return (
        <>
        <Navbar/>
         <Hero
         cName="hero-mid"
         heroImg={travelImg}
         title="About"
         btnClass="hide"
         />
         <ViewDetails />
         <Footer/>
        </>
        
    )


}

export default ViewDetailsPage;